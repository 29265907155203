.ant-tree-treenode {
    width: 100% !important;
}

.ant-tree-node-content-wrapper{
    width: 100%  !important;

}

.tree-node-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;

    .action-button {
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover .action-button {
        opacity: 1;
    }
}
