.button-cover {
    width: max-content;
    height: max-content;
    .custom-file-label {
        cursor: pointer;
        .custom-file-input {
            display: none;
        }

        .button-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            color: #1890FF;
            border: 1px solid #1890FF;
            border-radius: 5px;
            .icon {
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .text {
                padding:  0px 5px 0px 5px;
            }
        }
    }
}
.ql-toolbar{
    display: none;
}
.ql-editor{
    border: 1px solid #919191;
    border-radius: 5px;
}