.cover-2 {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.layout-detail {
  &__container {
    max-width: 480px;
    width: 480px;
    margin: 0 auto;
    padding: 0 10px 10px;
  }
}

.box {
  // position: relative;
  // overflow: hidden;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
}

// .box-cover {
//   flex-direction: row;
//   // justify-content: space-between;
//   // align-items: center;
//   // width: 100%;
// }

.image {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-cover {
  .button {
    width: 30%;
  }
}

.button-zoom {
  .button {
    width: 100%;
  }
}

.item-select-container {
  border-width: 1px;
  margin-bottom: 10px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #E9E9E9 ;
}

.selected {
  background-color: #BAE7FF ; 
  border-color: #233D7C;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: red;
}

@media (max-width: 768px) and (min-width: 100px) {
  .layout-detail {
    &__container {
      width: 100vw;
      margin: 0 0;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}