    .ant-select-tree-switcher-icon {
        font-size: 14px !important;

    }

    .ant-select-tree-title {
        font-size: 14px !important;

    }

    .ant-select-tree-treenode-active {
        background-color: rgba(202, 202, 202, 0.244);
    }