@import '../utils/helpers/mixin.scss';
.body__page {
  overflow: auto;
}
.layout {
  &__header {
    display: flex;
    align-items: center;

    .header {
      &__logo {
        display: flex;
      }

      &__account-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        background-color: #22252b;
        border-color: #22252b;
        padding: 20px 15px;
        border-radius: 30px;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }

        strong {
          color: white;
          margin-right: 10px;
        }

        .anticon {
          color: white;
        }
      }
    }
  }

  &__body {
    min-height: calc(100vh - 64px);
    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 30px 16px;
    }

    .container-fluid {
      padding: 30px 16px;
    }
  }
}

.icon_collapsed {
  float: right;
  margin: 10px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
}

.responsive_dashboard {
  z-index: 9;
  position: fixed;
  @include breakpoint(sx) {
    position: fixed;
    top: 64px;
    left: 0px;
    z-index: 999999;
  }

  .ant-layout-sider {
    height: 100vh;
  }
}

.ant-layout-header {
  padding: 0 50px !important;
  @include breakpoint(sx) {
    padding: 10px !important;
  }
}
.layout__header {
  position: fixed;
  width: 100vw;
  z-index: 9999;
}
.layout__body {
  margin-top: 64px;
}
.webview {
  min-height: 100vh;
  margin-top: 0;
  .body__page {
    left: 0;
  }
}
.body__sidebar {
  .ant-menu-submenu-title {
    padding-left: 10px !important;
  }
  .ant-menu-item {
    padding-left: 20px !important;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00152948;
  z-index: 100;
}
