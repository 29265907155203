  
  .layout-scsc {
    &__scsc-container {
      max-width: 480px;
      width: 480px;
      margin: 0 auto;
      padding: 10px 10px 10px;
    }
  }

@media (max-width: 768px) and (min-width: 100px) {
  .layout-scsc {
    &__scsc-container {
      width: 100vw;
      margin: 0 0;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
  